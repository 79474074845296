import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { estados } from '../../assets/cidades.json';

const Container = styled.div`
  border-top: 4px solid #f1a857;
  border-bottom: 1px solid #ffffff;

  position: relative;

  .filter-results {
    font-size: 16px;
    color: #40403f;
    letter-spacing: 0.36px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    i.icon-filter {
      display: none;
      width: 20px;
      height: 20px;
      margin-right: 7px;
      background: url(/images/streamline-icon-filter-1@140x140@2x-branco.png) no-repeat center;
      background-size: contain;
    }

    i.icon-arrow {
      background: url(/images/arrow-down.png) no-repeat center;
      background-size: contain;
      margin-left: 13px;
      width: 11px;
      height: 15px;
    }
    &.open {
      background: #f1a857;
      color: #fff;

      i.icon-filter {
        display: inline-block;
      }

      i.icon-arrow {
        background: url(/images/arrow-right.png) no-repeat center;
        width: 15px;
        transform: rotate(-90deg);
      }
    }
  }

  .filter-options {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;

    box-shadow: 0 20px 30px 0px rgba(0,0,0,0.5);
    background-color: #d8d8d8;
    z-index: 1;

    ul {
      & > li {
        border-bottom: 1px solid #ffffff;

        .header-title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          padding-top: 25px;
          padding-bottom: 25px;

          > span {
            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 600;
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 14px;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            vertical-align: middle;
            &.icon-estado {
              background-image: url(/images/streamline-icon-maps-pin-1140x140.png);
            }
            &.icon-cidade {
              background-image: url(/images/streamline-icon-style-three-pin-shop140x140.png);
            }
            &.icon-profissao {
              background-image: url(/images/streamline-icon-office-desk-lamp140x140.png);
            }
          }

          .icon-arrow {
            width: 11px;
            height: 15px;
            background: url(/images/arrow-down.png) no-repeat center;
            vertical-align: middle;
            margin-left: 15px;

            &.active-arrow {
              transform: rotate(180deg);
            }
          }
        }

        &:first-child,
        &:last-child {
          display: none;
        }

        input {
          width: 100%;
          padding: 10px 13px;
          height: 36px;
        }

        .box-option {
          border-bottom: 0;
          padding: 0;
          background-color: #fff;
          white-space: nowrap;
          width: 196px;

          &.active {
            background: rgba(0, 0, 0, 0.1);
            display: flex;
          }

          div {
            padding: 10px 13px;
            border-bottom: 1px solid #DEDEDE;

            cursor: pointer;

            height: 100%;
            width: 100%;

            span {
              font-weight: 600;
              color: #40403F;
            }

            i {
              border: 2px solid #ffffff;
              min-width: 20px;
              height: 20px;
              background: transparent;
              margin-left: 6px;
              appearance: none;
              border-radius: 3px;

              &.active {
                border: 2px solid #ffffff;
                background: url(/images/check.svg) no-repeat center #fff;
              }
            }
          }

          &:first-child {
            margin-left: 20px;
          }

          &:first-child,
          &:last-child {
            display: flex;
          }
        }
      }
    }
  }

  @media (min-width: 767px) {
    .filter-results {
      display: none;
    }

    .filter-options {
      position: relative;

      box-shadow: none;
      top: 0;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        max-width: 1200px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;

        li {
          border-right: 1px solid #ffffff;
          border-bottom: 0;
          max-width: 17%;

          .header-title {
            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 400;
            position: relative;
            width: 100%;
            padding: 0 20px;
            height: 60px;
            justify-content: center;
            align-items: center;
            display: flex;

            > span {
              text-align: center;
            }

            .icon-arrow {

              &.active-arrow {
                transform: rotate(0);
              }
            }
          }

          &:first-child,
          &:last-child {
            display: flex;
          }

          &:last-child {
            border-right: 0;
            padding-right: 0;
            padding-left: 0;
            justify-content: flex-end;
            margin-left: auto;

            a {
              cursor: pointer;
              color: #40403f;
              font-size: 14px;
              letter-spacing: 0.32px;
              font-weight: 600;
              padding-right: 15px;
              padding-left: 15px;

              background: transparent;

              display: flex;
              align-items: center;
              text-align: left;
              justify-content: flex-end;

              height: 60px;
              width: 100%;

              span {
                width: 60px;
              }

              i {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(/images/streamline-icon-keyboard-arrow-return@40x40@2x.png) no-repeat center;
                background-size: contain;
                margin-right: 12px;
              }

              &:hover {
                background: #fff;
              }
            }
          }

          &:first-child {
            padding-left: 0;
            max-width: 90px;
            padding-right: 20px;
            height: 60px;
            align-items: center;

            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 400;
          }

          &:hover {
            .header-title {
              background: #ffffff;
            }

            .sub-item {
              display: block;
            }
          }

          .sub-item {
            display: none;
            position: absolute;
            left: 0;
            max-height: 250px;
            overflow-y: auto;
            overflow-x: hidden;

            background: #fff;
            
            top: calc(100% + 1px);
            margin: 0;
          }

          .box-option {
            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-left: 0;
            }

            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }
`;

export default function FilterProjects(props) {
  const profissoes = [
    'Arquiteto', 'Designer de interiores', 'Marceneiro'
  ]
  const estadosList = [
    {sigla: "ac", nome: "Acre"},
    {sigla: "al", nome: "Alagoas"},
    {sigla: "am", nome: "Amazonas"},
    {sigla: "ap", nome: "Amapá"},
    {sigla: "ba", nome: "Bahia"},
    {sigla: "ce", nome: "Ceará"},
    {sigla: "df", nome: "Distrito Federal"},
    {sigla: "es", nome: "Espírito Santo"},
    {sigla: "go", nome: "Goiás"},
    {sigla: "ma", nome: "Maranhão"},
    {sigla: "mt", nome: "Mato Grosso"},
    {sigla: "ms", nome: "Mato Grosso do Sul"},
    {sigla: "mg", nome: "Minas Gerais"},
    {sigla: "pa", nome: "Pará"},
    {sigla: "pb", nome: "Paraíba"},
    {sigla: "pr", nome: "Paraná"},
    {sigla: "pe", nome: "Pernambuco"},
    {sigla: "pi", nome: "Piauí"},
    {sigla: "rj", nome: "Rio de Janeiro"},
    {sigla: "rn", nome: "Rio Grande do Norte"},
    {sigla: "rs", nome: "Rio Grande do Sul"},
    {sigla: "ro", nome: "Rondônia"},
    {sigla: "rr", nome: "Roraima"},
    {sigla: "sc", nome: "Santa Catarina"},
    {sigla: "se", nome: "Sergipe"},
    {sigla: "sp", nome: "São Paulo"},
    {sigla: "to", nome: "Tocantins"},
  ]
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [openResultsMobile, setOpenResultsMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [estadoActive, setEstadoActive] = useState(false);
  const [cidadeActive, setCidadeActive] = useState(false);
  const [profissaoActive, setProfissaoActive] = useState(false);


  const resize = () => {
    if (document.body.clientWidth > 767) {
      setOpenResultsMobile(true);
      setEstadoActive(false);
      setCidadeActive(false);
      setProfissaoActive(false);
    } else {
      setOpenResultsMobile(false);
    }
  }

  const resizeCarousel = () => {
    if (document.body.clientWidth > 540) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }

  const filterCidade = (e) => {
    if (e.target.value.length > 2) {
      const filterCidade = []
      cidades.map((item) => {
        if (item.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
          filterCidade.push(item)
      })
      setCidades(filterCidade)
    } else {
      const cidadesPosts = []
      props.allPosts.map(item => {
        if (cidadesPosts.indexOf(item.city) < 0)
          cidadesPosts.push(item.city)
      })
      setCidades(cidadesPosts);
    }
  }

  const filterEstado = (e) => {
    if (e.target.value.length > 2) {
      const filterEstado = []
      estadosList.map((item) => {
        if (item.nome.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
          filterEstado.push(item)
      })
      setEstados(filterEstado)
    } else {
      setEstados(estadosList)
    }
  }

  useEffect(() => {
    resize();
    resizeCarousel();
    setCidades([])
    let initialStates = [];
    estadosList.map((item) => {
      props.allPosts.map(post => {
        if (post.uf.toLowerCase() === item.sigla){
          const found = initialStates.some(el => el.sigla === item.sigla);
          if(!found){
            initialStates.push({sigla: item.sigla, nome: item.nome});
          }
        }
      })
    })
    setEstados(initialStates);
    estados.map((item) => {
      if (item.sigla.toLowerCase() === props.estado) {
        const cidadesPosts = []
        props.allPosts.map(item => {
          if (cidadesPosts.indexOf(item.city) < 0 && props.estado === item.uf.toLowerCase()){
            cidadesPosts.push(item.city)
          }
        })
        setCidades(cidadesPosts);
      }
    });
  }, [props])

  function toggleMobileResults() {
    setOpenResultsMobile(!openResultsMobile);
  }

  useEffect(() => {
    resize();
    resizeCarousel();

    window.addEventListener('resize', () => resize);
    window.addEventListener('resize', () => resizeCarousel);

    return () => {
      window.removeEventListener('resize', () => resize);
      window.removeEventListener('resize', () => resizeCarousel);
    };
  }, []);

  function toggleMenuCidade() {
    setCidadeActive(!cidadeActive);
  }

  function toggleMenuEstado() {
    setEstadoActive(!estadoActive);
  }

  function toggleMenuProfissao() {
    setProfissaoActive(!profissaoActive);
  }

  return (
    <Container>
      <button
        className={`${openResultsMobile ? 'filter-results open' : 'filter-results'}`}
        onClick={toggleMobileResults}
      >
        <i className="icon-filter"></i>
        <span>{openResultsMobile ? 'Filtrar resultados' : 'Filtrar projetos'}</span>
        <i className="icon-arrow"></i>
      </button>

      {openResultsMobile && (
        <div className="filter-options">
          <ul>
            <li>
              Filtrar por:
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuEstado}>
                <i className="icon icon-estado"></i>
                <span>Estado</span>
                <i className={estadoActive ? 'icon-arrow active-arrow' : 'icon-arrow'}></i>
                <div className="sub-item">
                  <input type="text" placeholder="Filtrar estados" onChange={ filterEstado } />
                  {estados.map((item, index) => (
                    <Link
                      to={props.estado === item.sigla ? `/profissionais/?estado=&profissao=${props.profissao}` : `/profissionais/?estado=${item.sigla}&profissao=${props.profissao}`}
                      key={index}
                      className={ ['box-option', props.estado === item.sigla ? 'active' : ''].join(' ') }
                    >
                      <div>
                        <span>{item.nome}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuCidade}>
                <i className="icon icon-cidade"></i>
                <span>Cidade</span>
                <i className={cidadeActive ? 'icon-arrow active-arrow' : 'icon-arrow'}></i>
                <div className="sub-item">
                  <input type="text" placeholder="Filtrar cidades" onChange={ filterCidade } />
                  {cidades.map((item, index) => (
                    <Link
                      to={props.cidade === item ? `/profissionais/?cidade=&profissao=${props.profissao}&estado=${props.estado}` : `/profissionais/?cidade=${item}&profissao=${props.profissao}&estado=${props.estado}`}
                      key={index}
                      className={ ['box-option', props.cidade === item ? 'active' : ''].join(' ') }
                    >
                      <div>
                        <span>{item}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuProfissao}>
                <i className="icon icon-profissao"></i>
                <span>Profissão</span>
                <i className={profissaoActive ? 'icon-arrow active-arrow' : 'icon-arrow'}></i>
                <div className="sub-item">
                  {profissoes.map((item, index) => (
                    <Link
                      to={props.profissao === item ? `/profissionais/?profissao=&cidade=${props.cidade}&estado=${props.estado}` : `/profissionais/?profissao=${item}&cidade=${props.cidade}&estado=${props.estado}`}
                      key={index}
                      className={ ['box-option', props.profissao === item ? 'active' : ''].join(' ') }
                    >
                      <div>
                        <span>{item}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </li>
            <li>
              {
                (props.profissao.length > 0 || props.estado.length > 0 || props.cidade.length > 0) && (
                  <Link to="/profissionais?profissao=&cidade=&estado=">
                    <i></i>
                    <span>Resetar Filtros</span>
                  </Link>
                )
              }
            </li>
          </ul>
        </div>
      )}
    </Container>
  )
}