import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import Seo from "../../components/seo";

import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";
import ContainerDefault from "../../components/container";

import FilterProfessionals from "./filter";

import GlobalStyle from "../../assets/globalStyles";

const Container = styled.div`
  border-top: 4px solid #f1a857;
  padding-top: 45px;
  padding-bottom: 45px;

  .professionals-list {
    li {
      margin-bottom: 35px;
    }
  }

  .professional-card {
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;

    .image-background {
      background-color: #9e9e9e;
      background-size: cover;
      background-position: center;
      height: 233px;
    }

    .professinal-info {
      padding: 0 24px 24px;
      text-align: center;

      .image-profile {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin: -45px auto 16px;
        background-size: cover;
        display: block;
        background-position: center;
      }

      .img-empty {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: #2d2d2c;
        margin: -45px auto 16px;
      }

      h3 {
        font: italic bold 20px "Times new roman";
        color: #323232;
        margin-bottom: 12px;
        text-align: left;
      }

      p {
        font-size: 12px;
        color: #959595;
        margin-bottom: 25px;
        text-align: left;

        span {
          text-transform: uppercase;
        }
      }

      .view-profile-button {
        max-width: 260px;
        height: 42px;
        border-radius: 3px;
        border: 2px solid #f1a857;
        margin: 0 auto;

        display: flex;
        align-items: center;

        transition: 0.2s all ease;

        span {
          color: #4f4e4e;
          font-size: 14px;
          font-weight: 500;
          padding-left: 30px;
          padding-right: 25px;

          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i {
          width: 57px;
          height: 100%;
          border-left: 1px solid #f1a857;
          margin-left: auto;
          background: url("/images/arrow-right-bold.png") center no-repeat;
          background-size: 16px;
        }

        &:hover {
          background: #f1a857;

          span {
            color: #ffffff;
          }

          i {
            border-left: 1px solid #e0933d;
            background: url("/images/arrow-right.png") center no-repeat;
            background-size: 16px;
          }
        }
      }
    }
  }

  .view-more {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 265px;
    }
  }

  .loadMore {
    width: 265px;
    height: 60px;
    border-radius: 3px;

    cursor: pointer;

    background: #f1a857;

    display: flex;
    align-items: center;

    transition: 0.2s all ease;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 29px;
      padding-right: 17px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      width: 55px;
      height: 100%;
      border-left: 1px solid #d99440;
      margin-left: auto;
      background: url("/images/icon-plus-white.svg") center no-repeat;
    }

    &:hover {
      background: #e59134;

      i {
        border-left: 1px solid #cd8226;
      }
    }
  }

  @media (min-width: 767px) {
    padding-top: 65px;
    padding-bottom: 65px;

    .professionals-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 22px;

      li {
        margin-bottom: 0;
      }
    }

    .view-more {
      margin-top: 65px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 100px;

    .professionals-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;
    }
  }
`;

const postsPerPage = 9;
let arrayForHoldingPosts = [];

export default function Professionals(props) {
  const results = useStaticQuery(graphql`
    {
      seo: prismicSeoPaginaProfissionaisMeuAmbiente {
        data {
          canonical {
            url
          }
          meta_description
          meta_og_description
          meta_og_image {
            url
          }
          meta_og_title
          meta_og_type
          meta_og_url {
            url
          }
          meta_title
        }
      }
    }
  `);

  const { seo } = results;

  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [profissao, setProfissao] = useState("");
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(9);
  const [hideLoadButton, setHideLoadButton] = useState(false);

  useEffect(() => {
    const search = window.location.search.replace("?", "").split("&");
    let profissao = "";
    let cidade = "";
    let estado = "";
    for (var item of search) {
      const node = item.split("=");
      switch (node[0]) {
        case "profissao":
          profissao = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "cidade":
          cidade = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "estado":
          estado = decodeURI(node[1].replace(/%20/g, " "));
          break;
        default:
      }
    }
    setCidade(cidade);
    setEstado(estado);
    setProfissao(profissao);

    arrayForHoldingPosts = [];
    setPostsToShow([]);
    setNext(9);

    loopWithSlice(0, postsPerPage, profissao, estado, cidade);
  }, [props]);

  const loopWithSlice = (start, end, profissao, estado, cidade) => {
    let professionalsFiltered = props.pageContext.professionals;
    if (profissao.length > 0 || cidade.length > 0 || estado.length > 0) {
      professionalsFiltered = props.pageContext.professionals.filter(
        (item) =>
          (profissao.length === 0 ||
            item.profession.toLowerCase() === profissao.toLowerCase()) &&
          (estado.length === 0 ||
            item.uf.toLowerCase() === estado.toLowerCase()) &&
          (cidade.length === 0 ||
            item.city.toLowerCase() === cidade.toLowerCase())
      );
    }
    const slicedPosts = professionalsFiltered.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
    if (
      arrayForHoldingPosts.length === props.pageContext.professionals.length
    ) {
      setHideLoadButton(true);
    }
  };

  const handleShowMorePosts = () => {
    const search = window.location.search.replace("?", "").split("&");
    let profissao = "";
    let cidade = "";
    let estado = "";
    for (var item of search) {
      const node = item.split("=");
      switch (node[0]) {
        case "profissao":
          profissao = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "cidade":
          cidade = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "estado":
          estado = decodeURI(node[1].replace(/%20/g, " "));
          break;
        default:
      }
    }

    loopWithSlice(next, next + postsPerPage, profissao, estado, cidade);
    setNext(next + postsPerPage);
  };

  const getThumbnail = (url) => {
    let imageArray = url.split("/");
    let imageName = imageArray[imageArray.length - 1];
    let imageThumb =
      "https://apimeuambiente.guararapes.com.br/assets/" + imageName;

    return imageThumb;
  };

  return (
    <>
      {seo && (
        <Seo
          metaTitle={seo.data.meta_title}
          metaDescription={seo.data.meta_description}
          title={seo.meta_og_title}
          description={seo.meta_og_description}
          image={seo.meta_og_image ? seo.meta_og_image.url : ""}
          url={seo.data.meta_og_url ? seo.data.meta_og_url : ""}
          type={seo.meta_og_type}
        />
      )}
      <Header />
      <FilterProfessionals
        cidade={cidade}
        estado={estado}
        profissao={profissao}
        allPosts={props.pageContext.professionals}
        posts={postsToShow}
      />
      <Container>
        <ContainerDefault>
          {postsToShow && (
            <ul className="professionals-list">
              {postsToShow.map((profile, index) => (
                <li key={index}>
                  <div className="professional-card">
                    <div
                      className="image-background"
                      style={
                        profile.background !== ""
                          ? {
                              backgroundImage: `url(${getThumbnail(
                                profile.background
                              )})`,
                            }
                          : null
                      }
                    ></div>
                    <div className="professinal-info">
                      {profile.company_avatar ? (
                        <span
                          className="image-profile"
                          style={{
                            backgroundImage: `url(${getThumbnail(
                              profile.company_avatar
                            )})`,
                          }}
                        ></span>
                      ) : (
                        <div className="img-empty"></div>
                      )}
                      <h3>{profile.company_name}</h3>
                      <p>
                        {profile.city}, <span>{profile.uf}</span>
                      </p>
                      <Link
                        to={`/perfil/${profile.id}`}
                        className="view-profile-button"
                      >
                        <span>Ver perfil e projetos</span>
                        <i></i>
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}

          <div className="view-more">
            {!hideLoadButton && (
              <button
                type="button"
                className="loadMore"
                onClick={handleShowMorePosts}
              >
                <span>Carregar Mais</span>
                <i></i>
              </button>
            )}
          </div>
        </ContainerDefault>
      </Container>
      <Newsletter />
      <Footer />

      <GlobalStyle />
    </>
  );
}
